import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueMeta from "vue-meta";
import VueCookieAcceptDecline from "vue-cookie-accept-decline";
import "vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css";
import VueGtag from "vue-gtag";
Vue.component("vue-cookie-accept-decline", VueCookieAcceptDecline);

Vue.config.productionTip = false;
Vue.use(
  VueGtag,
  {
    config: { id: "G-1T8F49GDSB" },
    appName: "proenduro.md datastream",
    pageTrackerScreenviewEnabled: true,
    enabled: false,
    onError(error) {
      console.log(error);
    },
  },
  router
);
Vue.use(VueMeta);

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
