<template>
  <v-container fluid class="pa-0">
    <v-row class="text-center ma-0">
      <v-col cols="12" class="pa-0">
        <v-carousel v-model="model" hide-delimiter-background :show-arrows="false" delimiter-icon="mdi-minus"
          :height="$vuetify.breakpoint.smAndDown ? '450px' : '600px'">
          <v-carousel-item>
            <v-img height="100%" class="pb-10" :src="require('../../assets/Carousel/Slider 1.webp')"
              :lazy-src="require('../../assets/Carousel/lazy/Slider 1.webp')">
              <v-row class="fill-height" align="center" justify="center">
                <v-col cols="10" md="10" lg="9" xl="9" class="text-left" align-self="end">
                  <div class="title_carousel white--text fontB my-3">
                    {{ getLang.HomePage.Slider.first_slide.text_line_1 }}
                  </div>
                  <!-- <div class="title_carousel white--text fontB my-3">
                    {{ getLang.HomePage.Slider.first_slide.text_line_2 }}
                  </div> -->
                  <div  v-if="$vuetify.breakpoint.mdAndUp" class="title_carousel3 primary--text fontB mb-3">
                    {{ getLang.HomePage.Slider.first_slide.text_line_3 }}
                  </div>
                  <v-btn class="mt-3 btn_carousel fontR px-12" color="btnYellow" elevation="0"
                    href="/category/Husqvarna_bikes">
                    {{ getLang.HomePage.Slider.first_slide.text_button }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-img>
          </v-carousel-item>
          <v-carousel-item>
            <v-img height="100%" class="pb-10" :src="require('../../assets/Carousel/Slider 2.webp')"
              :lazy-src="require('../../assets/Carousel/lazy/Slider 2.webp')">
              <v-row class="fill-height" align="center" justify="center">
                <v-col cols="10" class="text-left" align-self="end">
                  <div class="title_carousel white--text fontB my-3">
                    {{ getLang.HomePage.Slider.second_slide.text_line_1 }}
                  </div>
                  <!-- <div class="title_carousel white--text fontB my-3">
                    {{ getLang.HomePage.Slider.second_slide.text_line_2 }}
                  </div> -->
                  <div  v-if="$vuetify.breakpoint.mdAndUp" class="title_carousel3 primary--text fontB mb-3">
                    {{ getLang.HomePage.Slider.second_slide.text_line_3 }}
                  </div>
                  <v-btn class="mt-3 btn_carousel fontR px-12" color="btnYellow" elevation="0"
                    href="/category/Fantic_bikes">
                    {{ getLang.HomePage.Slider.second_slide.text_button }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-img>
          </v-carousel-item>
          <v-carousel-item>
            <v-img height="100%" class="pb-10" :src="require('../../assets/Carousel/Slider 3.webp')"
              :lazy-src="require('../../assets/Carousel/lazy/Slider 3.webp')">
              <v-row class="fill-height" align="center" justify="center">
                <v-col cols="10" lg="9" class="text-left" align-self="end">
                  <div class="title_carousel white--text fontB my-3">
                    {{ getLang.HomePage.Slider.third_slide.text_line_1 }}
                  </div>
                  <!-- <div class="title_carousel white--text fontB my-3">
                    {{ getLang.HomePage.Slider.third_slide.text_line_2 }}
                  </div> -->
                  <div  v-if="$vuetify.breakpoint.mdAndUp" class="title_carousel3 primary--text fontB mb-3">
                    {{ getLang.HomePage.Slider.third_slide.text_line_3 }}
                  </div>
                  <v-btn class="mt-3 btn_carousel fontR px-12" color="btnYellow" elevation="0"
                    href="/category/Tm_bikes">
                    {{ getLang.HomePage.Slider.third_slide.text_button }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-img>
          </v-carousel-item>
          <v-carousel-item>
            <v-img height="100%" class="pb-10" :src="require('../../assets/Carousel/Slider 4.webp')"
              :lazy-src="require('../../assets/Carousel/lazy/Slider 4.webp')">
              <v-row class="fill-height" align="center" justify="center">
                <v-col cols="10" md="9" class="text-left" align-self="end">
                  <div class="title_carousel white--text fontB my-3">
                    {{ getLang.HomePage.Slider.four_slide.text_line_1 }}
                  </div>
                  <!-- <div class="title_carousel white--text fontB my-3">
                    {{ getLang.HomePage.Slider.third_slide.text_line_2 }}
                  </div> -->
                  <div v-if="$vuetify.breakpoint.mdAndUp" class="title_carousel3 primary--text fontB mb-3">
                    {{ getLang.HomePage.Slider.four_slide.text_line_3 }}
                  </div>
                  <v-btn class="mt-3 btn_carousel fontR px-12" color="btnYellow" elevation="0"
                    href="/category/Rieju_bikes">
                    {{ getLang.HomePage.Slider.four_slide.text_button }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-img>
          </v-carousel-item>
          <v-carousel-item>
            <v-img height="100%" class="pb-10" :src="require('../../assets/Carousel/Slider 5.webp')"
              :lazy-src="require('../../assets/Carousel/lazy/Slider 5.webp')">
              <v-row class="fill-height" align="center" justify="center">
                <v-col cols="10" md="9" class="text-left" align-self="end">
                  <div class="title_carousel white--text fontB my-3">
                    {{ getLang.HomePage.Slider.five_slide.text_line_1 }}
                  </div>
                  <!-- <div class="title_carousel white--text fontB my-3">
                    {{ getLang.HomePage.Slider.third_slide.text_line_2 }}
                  </div> -->
                  <div v-if="$vuetify.breakpoint.mdAndUp" class="title_carousel3 primary--text fontB mb-3">
                    {{ getLang.HomePage.Slider.five_slide.text_line_3 }}
                  </div>
                  <v-btn class="mt-3 btn_carousel fontR px-12" color="btnYellow" elevation="0"
                    href="/category/Bud_racing">
                    {{ getLang.HomePage.Slider.five_slide.text_button }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-img>
          </v-carousel-item>
          <v-carousel-item>
            <v-img height="100%" class="pb-10" :src="require('../../assets/Carousel/item6.png')"
              :lazy-src="require('../../assets/Carousel/lazy/item6.png')">
              <v-row class="fill-height" align="center" justify="center">
                <v-col cols="10" md="9" class="text-left" align-self="end">
                  <div class="title_carousel white--text fontB my-3">
                    {{ getLang.HomePage.Slider.six_slide.text_line_1 }}
                  </div>
                  <!-- <div class="title_carousel white--text fontB my-3">
                    {{ getLang.HomePage.Slider.four_slide.text_line_2 }}
                  </div> -->
                  <div v-if="$vuetify.breakpoint.mdAndUp" class="title_carousel3 primary--text fontB mb-3">
                    {{ getLang.HomePage.Slider.six_slide.text_line_3 }}
                  </div>
                  <v-btn class="mt-3 btn_carousel fontR px-12" color="btnYellow" elevation="0" href="/category/Nils">
                    {{ getLang.HomePage.Slider.six_slide.text_button }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-img>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>

    <v-row justify="center" class="text-center menu_sub pa-5 ma-0">
      <v-col cols="12" md="11">
        <v-card color="transparent" flat>
          <v-btn @click="GoTo('Motocross')" class="btns_menu fontB mx-md-4" color="#fff" text>
            {{ getLang.HomePage.SubSliderCategories.komponenti }}
          </v-btn>
          <v-btn @click="GoTo('Enduro')" class="btns_menu fontB mx-md-4" color="#fff" text>
            {{ getLang.HomePage.SubSliderCategories.enduro }}
          </v-btn>
          <v-btn @click="GoTo('Road_bikes')" class="btns_menu fontB mx-md-4" color="#fff" text>
            {{ getLang.HomePage.SubSliderCategories.road }}
          </v-btn>
          <v-btn @click="GoTo('Urban')" class="btns_menu fontB mx-md-4" color="#fff" text>
            {{ getLang.HomePage.SubSliderCategories["e-road"] }}
          </v-btn>
          <v-btn @click="GoTo('Bud_racing')" class="btns_menu fontB mx-md-4" color="#fff" text>
            {{ getLang.HomePage.SubSliderCategories.accessories }}
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CarouselBlock",
  data: () => ({
    model: 0,
  }),
  computed: {
    ...mapGetters(["getLang"]),
  },
  methods: {
    GoTo(categ) {
      this.$router.push({
        name: "categoryPage",
        params: {
          name: 'All',
          subname: categ
        }
      }).catch(() => { });
    }
  }
};
</script>
<style scoped>
@media only screen and (max-width: 600px) {
  .title_carousel3 {
    font-size: 1.6rem !important;
  }

  .title_carousel {
    font-size: 1.8rem !important;
  }

  .btn_carousel {
    zoom: 0.85;
  }
}

.btn_carousel {
  text-shadow: 0 0 3px #574A0A;
  color: #fff;
  border-radius: 0px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.title_carousel {
  font-weight: 600;
  font-size: 45px;
  line-height: 90.31%;
  letter-spacing: -0.025em;
  text-shadow: 5px 5px 10px #000;
}
.title_carousel3 {
  font-weight: 600;
  font-size: 35px;
  line-height: 38px;
  letter-spacing: -0.025em;
  text-shadow: 5px 5px 10px #000;
}

.btns_menu {
  text-transform: none;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0px;
}

.menu_sub {
  background: #040c1b;
}
</style>
<style>
.v-carousel__controls__item.v-item--active {
  opacity: 0.9;
}

@media only screen and (max-width: 500px) {
  .v-carousel__controls__item {
    opacity: 0.35;
    background: #dcbb10;
    width: 45px !important;
    border-radius: 10px;
    height: 8px !important;
  }
}

@media only screen and (min-width: 500px) {
  .v-carousel__controls__item {
    opacity: 0.35;
    background: #dcbb10;
    width: 120px !important;
    border-radius: 10px;
    height: 8px !important;
  }
}

.v-carousel__controls__item:hover {
  opacity: 0.5;
  background: #dcbb10;
}

.v-carousel__controls__item .v-icon {
  display: none;
}
</style>